:root {
  /* Primary Colors */
  --primary-color: #1f0a68;
  --primary-dark: #b1a0eb;
  --primary-light: #f7f4ff;

  /* Secondary Colors */
  --secondary-color: #e67e22;
  --secondary-dark: #d35400;
  --secondary-light: #f39c12;

  /* Background Colors */
  --background-color: #efefef;
  --background-light: #ffffff;
  --background-dark: #e1e0e0;

  /* Text Colors */
  --text-color: #333333;
  --text-light: #ffffff;
  --text-dark: #555555;

  /* Accent Colors */
  --accent-color: #c0392b;
  --accent-dark: #a93226;
  --accent-light: #e74c3c;

  /* Border Colors */
  --border-color: #dddddd;
  --border-dark: #aaaaaa;
  --border-light: #e9e9e9;

  --browse-file: #e7e7e7;
  --browse-bg: #31245c;
  // font sizes 
  --fs-xs : calc(1vh + 0.1vw);
  --fs-sm: calc(1vh + 0.25vw);
  --fs-m: calc(2.5vh + 0.5vw);
  --fs-l: calc(3vh + 1.5vw);
  --fs-xl: calc(6vh + 1.5vw);
  --fs-xxl: calc(8vh + 2vw) ;
  --fs-table-heading: calc(0.3vh + 1.5vw);
  
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

* {
  margin: 0 !important;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

body {
  background-color: var(--primary-light);
}

h1 {
  color: var(--primary-color);
}

input {
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: var(--text-dark) !important;
  border: none;
  outline: none;
  width: 128px;

  &:focus {
    border: none;
    outline: none;
  }
}

.primary-button {
  cursor: pointer !important;
  color: var(--primary-color);
  background-color: var(--text-light);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-size: 16px !important;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
  transition: all 0.3s ease;
  &:hover {
    background-color: var(--background-color);
  }
}

.secondary-button {
  cursor: pointer !important;
  background-color: var(--primary-color);
  color: var(--text-light);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 12px !important;
  border: none;
  border-radius: 20px;
  transition: all 0.3s ease;
  &:hover {
    background-color: var(--background-color);
  }
}

.main {
  display: flex;
  .breadcrumb-main {
    display: flex;
    padding: 8px;
    
  }
  .main-content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 98px);
    width: 100%;
  }
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.green {
  color: rgb(27, 225, 27) !important;
}

.red {
  color: red !important;
}

.blue {
  color: blue !important;
}

/* Define the custom scrollbar styles */

/* Webkit (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #a0a0a0; /* Set the color of the scrollbar thumb */
  border-radius: 6px; /* Set the border radius of the thumb */
}

/* Firefox */
/* Please note that as of my last update in January 2022, Firefox did not support styling the scrollbar. */

/* MS Edge and IE */
/* Styling for older versions of Microsoft Edge and Internet Explorer */
/* Edge Legacy and IE 11 */
* {
  scrollbar-width: thin;
  scrollbar-color: #a0a0a0 transparent;
}

/* Apply the custom scrollbar styles to the desired element */
/* Replace 'your-element' with the appropriate selector for your scrollbar */
* {
  /* Include the scrollbar styles */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #a0a0a0 transparent; /* For Firefox */

  /* Webkit (Chrome, Safari, Edge) */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 6px;
  }

  /* MS Edge and IE */
  * {
    scrollbar-width: thin;
    scrollbar-color: #a0a0a0 transparent;
  }
}

.add-session-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #0000009d;
  // backdrop-filter: blur(1px); /* You can adjust the blur amount as needed */
  z-index: 9999999;
}
