.AddBannerButton-container {
  background-color: white;
  border-radius: 50%;
  box-shadow: 10px 10px 20px rgb(57, 57, 57);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 999999;
  cursor: pointer;
}
