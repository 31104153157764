.PaymentDetails-container-parent{
  padding: 40px;
}

.PaymentDetails-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
        overflow-y: auto;
        background-color: inherit;
        position: relative;
        border: none;
    gap: 24px;
    
    .basic-info{
        width: 50vw;
        padding: 24px;
        flex-direction: column;
        position: relative;
    border-radius: 20px;
        border: 1px solid #1111111a;
        background-color: var(--background-light);
       
        .bottom{
            display: flex;
            justify-content: center;
            gap: 24px;
        }
        button{
            background-color: var(--primary-color);
            color: white;
            border: none;
            font-size: 16px;
            padding: 8px 12px;
            cursor: pointer;
          }
        .info-img{
            display: flex;
            justify-content: center;
            width: 100%;
            img{
                display: flex;
                align-items: center;
                border-radius: 50%;
                width: 250px;
                height: 250px;
            }
        }
        .heading {
            display: flex;
            justify-content: space-between;
    border: none;
          padding-bottom: 12px;
          
        }
        .info {
          display: flex;
          flex-direction: column;
          gap: 12px;
          
          .row {
            display: flex;
            justify-content: start;
            .col {
              width: 100%;
              display: flex;
              .info-field {
                width: 100%;
                font-weight: 700;
              } 
              .info-value {
                width: 100%;
                a{
                  color: blue;
                  text-decoration: underline;
                  font-size: 0.9em;
                }
                .gender-radio{
                  display: flex;
                  gap: 24px;
                  .gender-text{
                    display: flex;
                    gap: 8px;
                  }
                }
                input {
                  z-index: 999;
                  width: 100%;
                  border-bottom: 1px solid #111;
                }
              }
            }
          }
        }
   
    }
}