.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .Login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 80px 80px;
    background-color: var(--background-light);
    border-radius: 20px;
    box-shadow: 2px 2px 60px rgba(69, 71, 209, 0.13);
    .img {
      padding: 32px 0 40px 0;
    }
    h2 {
      margin-bottom: 20px;
    }
  }
}

.Google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: none;
  width: 100%;
  border-radius: 10px;
  gap: 12px;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Google-login-button:hover {
  background-color: #3517a3;
}

.Google-icon {
  margin-right: 10px;
}

.login-inputs {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  .or {
    display: flex;
    justify-content: center;
  }
}

hr {
  width: 100%;
}

.signup {
  color: #3b06fd;
  font-weight: 600;
  cursor: pointer;
}

.forgot :hover {
  color: #3700ff;
}

.forgot {
  cursor: pointer;
}
