.Banner-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  p {
    display: flex;
    width: 100%;
    justify-content: end;
    font-weight: 800;
  }
  .delete-button {
    position: absolute;
    top: 40px;
    right: 40px;
    padding: 20px;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.3s ease;
    &:hover {
      background-color: #ffffff4a;
    }
  }
  img {
    height: fit-content;
    width: 100%;
  }
}
