.admin-ep-main {
  width: 100%;
  height: 100%;
  background-color: #f7f4ff;

  .admin-ep-child {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1em ;
    padding: 40px;
  }

  .card1,
  .card2  {
width: 49%;
height: 60%;
    display: flex;
    flex-direction: column;

    cursor: pointer;
img{
 width: 100%;
 height: auto;
 object-fit: cover;
}
    .aepc-links {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        color: #111111a8;
        font-size: 20px;
        font-weight: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .admin-ep-child {
    width: 80%;
    height: 80%;
  }
}
