.WebinarCard-container {
  width: 400px;
  background-color: var(--background-light);
  // padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .webinar-container {
    display: flex;
    flex-direction: column;
    .webinar-top {
      display: flex;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .webinar-mid {
      padding: 20px;
      .row {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        gap: 20;
        width: 250px;
        .date {
          color: rgb(8, 174, 86);
          font-size: 20px;
          font-weight: 700;
        }
        .col {
          display: flex;
        }
      }
    }
    .webinar-title {
      display: flex;
      padding: 20px 20px;
    }
    .webinar-bottom {
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      padding: 20px;
      .button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 16px;
        background-color: var(--primary-color);
        color: var(--text-light);
        border-radius: 50px;
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
