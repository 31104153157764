.Institutes-container {
  width: 100%;
  overflow-y: auto;
  font-size: 16px;
  .filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid black;
    margin-bottom: "10px";
    gap: 20px;

    a{
      padding: 1.1em;
      border: 1px solid #1F0A68;
      font-size: 0.9em;
      background-color: #1F0A68;
      color: #ffffff;
      border-radius: 5px;
    }
    a:hover{
      background-color: #ffffff;
      transition: all 0.3s ease;
      color: #1F0A68;
    }
        .reset-filters-button {
          border: 2px solid;  // Border color
          padding: 12px 15px;
        }
  }
  .btn_main {
    display: flex;
    gap: 20px;
  }
  .heading {
    background-color: var(--primary-light);
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.151);
    h4 {
      // padding: 16px;
      font-size: 10px;
    }
    .row {
      display: flex;
      padding: 20px;
      margin: 29px;
      text-align: start;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      .col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        // font-size: 16px;
        color: var(--text-dark);
      }
    }
  }
}
.sticky {
  position: sticky !important;
  top: 0;
}
.institute-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  width: 100%;
  .table {
    .no-institutes-found{
      display: flex;
        justify-content: center;
        text-align: center;
        color: #333;
    }
    .row {
      display: flex;
      border-bottom: 1px solid rgba(24, 45, 107, 0.253);
      padding: 20px;
      margin: 29px;
      text-align: start;
      justify-content: space-between;
      gap: 40px;
      .col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: var(--text-dark);
        font-size: var(--fs-sm);
        p {
          border: 1px solid black;
          border-radius: 20px;
          padding: 4px 12px;
        }
        img {
          border-radius: 50%;
          width: 48px;
          -webkit-user-drag: none;
        }
      }
    }
  }
}
// edited
@media (max-width: 860px) {
  .Institutes-container {
    width: fit-content;
  }
  .institute-table-container {
    width: fit-content;
    overflow-y: scroll;

    .row {
      width: 700px;
    }
  }
}
