.add-accomm-popup-main{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #1111113f;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(8px);
    .add-accomm-popup-sub{
        width: max-content;
        height: max-content;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 1em;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

        .add-accomm-form{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
    }
}