@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Ethiopic:wght@100..900&display=swap");
.webinar-profile-parent {
  width: 100%;
  padding: 10px;
  padding-bottom: 1em;
  display: flex;
  justify-content: center;
  // align-items: center;

  .wp-dets-edit-now {
    display: flex;
    justify-content: flex-end;
    // width: 100%;
    padding: 1em;
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 0.8em;
    button {
      font-size: 1.1em;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1em 2em 1em 2em;
      background-color: #ffffff;
      border: 1px solid #ff2f2f;
      color: #ff4646;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;
    }
    button:hover {
      background-color: #fc4747;
      color: #ffffff;
      border: 1px solid #ff4444;
      transition: all ease 0.3s;
    }
  }
  .wpde-save-cancel {
    gap: 1em;
  }
  .webinar-profile-child {
    width: 70%;
    height: 100%;
    display: flex;
    gap: 2em;
    flex-direction: column;
    padding: 20px;

    .webinar-profile-image {
      width: 100%;
      border-radius: 20px;
      // box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

      img {
        width: 100%;
        height: auto;
        border-radius: 20px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

        // object-fit: cover;
      }
    }

    .webinar-profile-dets {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1em;
      padding: 2em;
      background-color: #ffffff;
      border-radius: 20px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

      .wp-dets-first {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        gap: 2em;
        h3 {
          font-size: 1.6em;
          color: #111111ce;
          text-align: left;
          width: 97%;
        }
        p {
          font-size: 0.9em;
          color: #1111118e;
        }
        textarea {
          resize: none;
          padding: 0.5em;
          width: 100%;
        }
      }
      p {
        font-size: 0.9em;
        color: #111111b2;
        span {
          // font-weight: bold;
          font-style: italic;
          color: #11111198;
        }
      }
      .wp-dets-time {
        display: flex;
        gap: 0.3em;
        padding-bottom: 1.5em;
        border-bottom: 1px solid #1111112c;

        g {
          height: 2em;
        }
      }
      .wp-dets-details {
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: flex-start;

        h3 {
          font-size: 1.1em;
        }
      }
      .wp-dets-learn {
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: flex-start;

        h3 {
          font-size: 1.1em;
        }

        .wp-dets-learn-blocks {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 1em;

          .wpdl-blocks-child {
            width: 30%;
            height: 80px;
            box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
              rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
            border-radius: 10px;
            padding: 1em;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
              font-size: 0.9em;

              span {
                font-family: "Noto Sans Ethiopic", sans-serif;
                font-size: 1.5em;
                font-optical-sizing: auto;
                font-style: normal;
                font-variation-settings: "wdth" 100;
                font-style: italic;
                font-weight: 100;
              }
            }
          }
        }
      }

      .wp-dets-speaker {
        padding-top: 1em;
        display: flex;
        flex-direction: column;
        gap: 1em;
        align-items: flex-start;

        h3 {
          font-size: 1.1em;
        }
      }

      .wp-dets-btn {
        width: 100%;

        a {
          width: 100%;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5em 0 0.5em 0;
          background-color: #1f0a68;
          color: #ffffff;
          font-weight: bold;
          cursor: pointer;
          border: 1px solid #1f0a68;
        }
        a:hover {
          background-color: #ffffff;
          border: 1px solid #1f0a68;
          transition: all ease 0.3s;
          color: #1f0a68;
        }
      }
    }
  }
  .registrant {
    display: flex;
    flex-direction: column;
    margin: 20px;

    .registrant-filters {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .search-bar {
        flex: 1;

        input[type="text"] {
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          width: 100%;
        }
      }

      .registrant-filters {
        // Add styles for additional filters if needed
      }
    }

    .registrant-details {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      grid-gap: 20px;

      .user-card {
        background-color: #f9f9f9;
        border: 1px solid #ddd;
        padding: 20px;
        border-radius: 5px;
        display: flex;
        gap: 2rem;

        .user-image {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-bottom: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .user-data {
          text-align: center;

          p {
            margin-bottom: 10px;
            font-weight: bold;
          }

          a {
            text-decoration: none;
            color: blue;
            font-weight: bold;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

// .WebinarProfile-container{
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   .webinar-profile{
//     position: relative;
//     background-color: var(--background-light);
//     box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
//     width: 50%;
//     border-radius: 20px;
//     .hamburger{
//       position: absolute;
//       right: 0;
//       padding-right: 20px;
//       padding-top: 20px;
//       cursor: pointer;
//     }
//     .drop-down-menu {
//       opacity: 0;
//       transform: translateY(-10px);
//       flex-direction: column;
//       padding: 8px;
//       margin: 0;
//       position: absolute;
//       right: 0px;
//       z-index: 999999;
//       top: 48px;
//       border-radius: 10px;
//       background-color: var(--background-light);
//       box-shadow: 2px 2px 6px #1111113a;
//       transition: all 0.5s cubic-bezier(0, 0.86, 0.18, 0.99) !important;
//       .menu-item {
//         display: flex;
//         align-items: center;
//       z-index: 999999;
//       gap: 8px;
//         padding: 4px;
//         margin: 0;
//         cursor: pointer;
//         border-radius: 5px;
//         &:hover {
//           background-color: var(--background-color);
//         }
//       }
//     }

//     .info-img{
//       .profile-pic{
//         img{
//           border-top-right-radius: 20px;
//           border-top-left-radius: 20px;
//           width: 100%;
//           height: 40vh;
//         }
//       }
//     }
//     .webinar-details{
//       display: flex;
//       flex-direction: column;
//       gap: 24px;
//       width: 100%;
//       padding: 20px;
//       .detail{
//         display: flex;
//         gap: 20px;
//         padding: 0px 100px 0px 0px;
//         label{
//           width: 100%;
//         }
//         input{
//           width: 100%;
//           border-bottom: 1px solid black;
//         }
//       }
//     }
//   }
// }
