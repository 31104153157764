.all-dashboard {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  // overflow-y: auto;
  .Dashboard-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    gap: 40px;
    overflow-y: auto;
    height: 100%;
    .business-dashbaord {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding: 20px;
      // edited
      h1{
       font-size: var(--fs-l);
      }
      .chart-row {
        display: flex;
        justify-content: center;
        gap: 20px;
        .col {
          width: 100%;
        }
      }
      .widgets-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 32px;
      }
      // edited
      @media (max-width:768px){
       .widgets-container{
        flex-direction: column;
        gap: 4px;
      
       }
       .chart-row{
        flex-direction: column;
       }
      }
    }
  }
}

