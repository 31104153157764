.Profile-container {
  .edit-profile-button {
   // edited
    padding: 6px 12px;
    border-radius: 24px;
    background-color: var(--primary-color);
    color: var(--background-light);
    cursor: pointer;
  }
  display: flex;
  justify-content: center !important;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  .profile-body {
    display: flex;
    width: 50vw;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    position: relative;
    .buttons {
      display: flex;
      justify-content: end;
      width: 100%;
      gap: 12px;
    }
    .profile-pic {
      display: flex;
      justify-content: center;
      // transform: translateY(-50%);
    }
    .edit-profile {
      display: flex;
      justify-content: end;
      width: 100%;
      z-index: 1;
      top: 314px;
      position: absolute;
      right: 0;
      cursor: pointer;
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      // transform: translateY(-80px);
      width: 50vw;
      gap: 48px;
      .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        input {
          width: 100%;
          height: 40px;
          border-bottom: 1px solid #111;
        }
        h1{
         font-size: var(--fs-m);
        }
      }
      .middle {
        display: flex;
        flex-direction: column;
        gap: 48px;
        width: 100%;
      }
      .bottom {
        display: flex;
        width: 100%;
        height: 140px;
        align-items: start;
        // transform: translateY(-20px);
      }
      h3 {
        font-weight: 400;
      }
    }
  }
}
