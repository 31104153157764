.NotificationsButton-container {
  border-radius: 50%;
  background-color: var(--background-light);
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 20px rgba(50, 42, 117, 0.11);
  cursor: pointer;
}

.unread-notifications {
  position: absolute;
  top: -4px;
  right: -4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: var(--primary-color);
  color: var(--background-light);
  font-weight: bold;
  font-size: 12px;
  border-radius: 50%;
}
