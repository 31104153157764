.ProfileDropDownMenu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  gap: 12px;
  position: relative;
  background-color: var(--background-light);
  border-radius: 50px;
  padding: 6px 8px;
  cursor: pointer;
  box-shadow: 2px 2px 20px rgba(50, 42, 117, 0.11);
  .left{
   .Avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #F8F4FF; /* Default background color */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: black; /* Text color */
    font-weight: 600;
  }
  }
  .dropdown-menu {
    position: absolute;
    background-color: #fff;
    top: 80px;
    right: 16px;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.27);
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      border-radius: 50%;
      width: 52px;
    }
  }
  .mid {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .right {
    display: flex;
    justify-content: center;
    width: 20px;
  }
}
// edited
@media only screen
and (max-width : 500px) {
  .ProfileDropDownMenu-container {
    .left {
      img {
        width: 40px;
      }
    }
}
}