.dropdown{
 .dropdown-toggle{
     cursor: pointer;
 }
 .dropdown-options{
     position: absolute;
     top: 109px;
     right: 227px;
     background-color: var(--background-light);
     padding: 10px;
     border-radius: 20px;
     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
     .edit{
         display: flex;
         justify-content: center;
         gap: 8px;
         cursor: pointer;
         padding: 24px 12px;
         border-radius: 10px;
         &:hover {
             background-color: var(--background-color);
           }
     }
     .delete{
         display: flex;
         justify-content: center;
         gap: 8px;
         padding: 24px 12px;
         cursor: pointer;
         border-radius: 10px;
         &:hover {
             background-color: var(--background-color);
           }
     }       
 }
}