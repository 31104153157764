@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.AddWebinar-container{
  .wwyl-inputs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 0.5em;
    padding-bottom: 1em;

    .learning-item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      gap: 0.3em;
      width: 100%;
      textarea{
        width: 100%;
      }
    }
    button{
      background: none;
      border: none;
      cursor: pointer;
    }
    button.wwyl-add-btn{
      padding: 0.5em 0.7em 0.5em 0.7em; 
      background-color: #1F0A68;
      color: #ffffff;
      border-radius: 5px;
      width: 100%;
      text-align: center;
    }
    .wwyl-delete-btn{
      background-color: rgba(255, 0, 0, 0.842);
      border-radius: 5px;
      padding: 0.3em 0.5em 0.3em 0.5em;
      span{
        color: #ffffff;

        svg{
          height: 1.2em;
          width: 1.2em;
          
        }
      }
    }
  }
}
.Add-webinar {
  display: flex;
  flex-direction: column;
  gap: 48px;
  border-radius: 20px;
  background-color: var(--background-light);
  width: 600px;
  height: 700px;
  padding: 20px;

  .edit-mode-form {
    .edit-mode-fields {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .add-fields {
        display: flex;
        gap: 4px;
        flex-direction: column;
        input {
          border: 1px solid black;
          border-radius: 12px;
          padding: 12px;
          width: 100%;
        }
      }
    }
    .input-upload {
      display: none;
      width: 100%;
    }

    .custom-upload-button {
      display: inline-block;
      padding: 10px 15px;
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      background-color: #3498db;
      border: 1px solid #3498db;
      border-radius: 5px;
      cursor: pointer;
    }

    .custom-upload-button:hover {
      background-color: #2980b9;
      border: 1px solid #2980b9;
    }

    .add-web-buttons {
      display: flex;
      gap: 8px;
      justify-content: space-evenly;
      .create {
        cursor: pointer;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        background-color: var(--background-color);
        padding: 12px 16px;
        border-radius: 10px;
        align-items: center;
        &:hover {
          background-color: var(--primary-color);
          color: var(--background-color);
        }
      }
      .cancel {
        width: 100%;
        display: flex;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        background-color: var(--background-color);
        padding: 12px 16px;
        // border-radius: 20px;
        &:hover {
          background-color: var(--primary-color);
          color: var(--background-color);
        }
      }
    }
  }
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    .spinner {
      color: #2200ff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%; /* Set the desired width */
      height: 40px; /* Set the desired height */
      margin: 0 auto; /* Adjust margin as needed */
      animation: rotate 1s linear infinite; /* Apply the animation */
    }
  }
}
