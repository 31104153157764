.add-profile-pic-panel {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999;
  }
  
  .ProfilecoverPic-container {
    display: flex;
    height: 300px;
    position: relative;
    top: 140px;
    width: 480px;
    border-radius: 6%;
    background-color: #fff;
    box-shadow: 2px 2px 10px #1111111b;
    img {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 6%;
      object-fit: cover;
      -webkit-user-drag: none;
      border: 5px solid whitesmoke;
    }
    .pic-upload-container {
      cursor: pointer;
      position: absolute;
      top: 280px;
      right: 11px;
      display: flex;
      background-color: var(--text-light);
      border-radius: 13px;
      padding: 7px 12px;
      box-shadow: 2px 2px 10px #1111111b;
      .camera-icon {
        color: var(--text-dark);
        transition: all 0.3s ease;
        &:hover {
          color: var(--text-color);
        }
      }
    }
  
    input {
      display: none;
    }
  }
  