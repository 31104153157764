.RecentPayments-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  .see-all-button {
    font-weight: 800;
    color: var(--primary-dark);
    cursor: pointer;
    display: flex;
    justify-content: end;
  }
  .payments-top {
    display: flex;
    justify-content: space-between;
    align-items: end;
    h1{
     font-size: var(--fs-l);
    }
  }
  .table {
    .row {
      display: flex;
      border-bottom: 1px solid rgba(24, 45, 107, 0.158);
      padding: 20px;
      margin: 29px;
      text-align: start;
      justify-content: space-between;
      .col {
        display: flex;
        width: 100%;
        font-size: 16px;
        color: var(--text-dark);
      }
    }
  }
}
@media (max-width:680px){
 .RecentPayments-container{
  width: fit-content;
  overflow-y: scroll;
  .row{
   width:700px;
   gap:10px;
  }
 }
}
