.BasicInfo-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background-light);
  padding: 24px;
  border: 1px solid #1111111a;
  border-radius: 20px;
  gap: 24px;
  .heading {
    border-bottom: 1px solid #1111111a;
    padding-bottom: 12px;
    h2{
     font-size: var(--fs-m);
     font-weight: unset;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    .row {
      display: flex;
      justify-content: start;
      .col {
        width: 100%;
        display: flex;
        .info-field {
          width: 100%;
          font-weight: 700;
          p{
           font-size: var(--fs-sm);
           font-weight: unset;
          }
        }
        .info-value {
          width: 100%;
          p{
           font-size: var(--fs-sm);
          }
          .gender-radio {
            display: flex;
            gap: 24px;
            .gender-text {
              display: flex;
              gap: 8px;
              font-size: 10px;
            }
          }
          input {
            // z-index: 999;
            width: 100%;
            border-bottom: 1px solid #111;
          }
        }
      }
    }
  }
}
@media (max-width:768px){
 .BasicInfo-container{
  display: block;
 }
}
