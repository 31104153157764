.User-container {
  width: 100%;
  overflow-y: auto;
  .heading {
    background-color: var(--primary-light);
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.151);
    h1 {
      padding: 16px;
    }
    .row {
      display: flex;
      padding: 20px;
      margin: 29px;
      text-align: start;
      justify-content: space-between;
     
      .col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 12px;
        color: var(--text-dark);
      }
    }
  }
}
.sticky {
  position: sticky !important;
  top: 0;
}
.user-table-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  width: 100%;
  .avatar {
   width: 40px;
   height: 40px;
   border-radius: 50%;
   background-color: #f1edf7; /* Default background color */
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 20px;
   color: black; /* Text color */
 }
  .pagination {
    display: flex;
    justify-content: center;
  }
  .top {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .table {
    .row {
      display: flex;
      border-bottom: 1px solid rgba(24, 45, 107, 0.253);
      padding: 20px;
      margin: 29px;
      text-align: start;
      justify-content: space-between;
      .col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // edited
        font-size: var(--fs-sm);
        color: var(--text-dark);
        button{
          border: none;
          background-color: transparent;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
        }
      }
        img {
          border-radius: 50%;
          width: 48px;
          -webkit-user-drag: none;
        }
      }
    }
  }
}
// edited
@media (max-width: 860px){
 .User-container{
  width: fit-content;
 }
 .user-table-container{
width: fit-content;
overflow-y: scroll;

 .row{
  width: 700px;
 }
 }
}
