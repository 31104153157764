.AddWebinarButton-container {
  .add-webinar-button {
    width: 200px;
    border-radius: 50px;
    height: 64px;
    h5{
     font-size: var(--fs-s);
     font-weight: unset;
    }
  }
}

