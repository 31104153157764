// .accommodation-main {
//   height: 100%;
//   width: 100%;
//   padding: 20px;

//   .accomm-heading {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     .accomm-buttons {
//       display: flex;
//       gap: 1rem;
//       button {
//         background-color: #1f0a68;
//         border: 1px solid #1f0a68;
//         color: #ffffff;
//         border-radius: 40px;
//         font-size: 0.9em;
//         padding: 1em 2em 1em 2em;
//         cursor: pointer;
//       }
//       button:hover {
//         background-color: #ffffff;
//         color: #1f0a68;
//         transition: all 0.5s ease;
//       }
//     }
//   }
//   .accomm-parent {
//     width: 100%;
//     padding: 20px 20px 20px 20px;
//     display: flex;
//     flex-wrap: wrap;
//     gap: 1em;
//     overflow-y: scroll;

//     .accomm-children {
//       width: 32%;
//       background-color: #ffffff;
//       border-radius: 6px;
//       padding: 1em 1em 1.2em 1em;
//       display: flex;
//       flex-direction: column;
//       gap: 0.7em;
//       box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

//       .accomm-gallery {
//         width: 100%;
//         display: flex;
//         .accomm-image-container {
//           width: 100%;
//           height: 260px;
//           overflow: hidden;
//           transition: 0.5s ease-in-out !important;
//           img {
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//             border-radius: 8px;
//             transition: 0.5s ease-in-out !important;
//           }
//         }
        
//       }

//       .accomm-child-1 {
//         display: flex;
//         justify-content: space-between;

//         .accomm-child-1-l {
//           h4 {
//             font-size: 1.1em;
//           }
//           p {
//             font-size: 0.8em;
//           }
//         }
//         .accomm-child-1-r {
//           display: flex;
//           align-items: flex-end;
//           justify-content: flex-end;
//           svg {
//             color: rgb(250, 184, 17);
//           }
//           p {
//             font-size: 0.8em;
//           }
//         }
//       }
//       .accomm-child-2 {
//         display: flex;
//         justify-content: space-between;

//         .accomm-child-2-l {
//           p {
//             font-size: 0.8em;

//             span {
//               font-size: 1.1em;
//               font-weight: 700;
//             }
//           }
//         }
//         .accomm-child-2-r {
//           display: flex;
//           align-items: flex-end;

//           button {
//             background-color: #1f0a68;
//             padding: 0.3em;
//             border: 2px solid #1f0a68;
//             border-radius: 5px;
//             color: #ffffff;
//             font-size: 0.9em;
//             cursor: pointer;
//           }
//           button:hover {
//             background-color: #ffffff;
//             border: 2px solid #1f0a68;
//             color: #1f0a68;
//             transition: all 0.3s ease;
//           }
//         }
//       }
//     }
//   }
// }

.accommodation-main {
  height: 100%;
  width: 100%;
  padding: 20px;

  .accomm-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .accomm-buttons {
      display: flex;
      gap: 1rem;

      button {
        background-color: #1f0a68;
        border: 1px solid #1f0a68;
        color: #ffffff;
        border-radius: 40px;
        font-size: 0.9rem;
        padding: 1em 2em;
        cursor: pointer;
        transition: background-color 0.3s ease;
      }

      button:hover {
        background-color: #ffffff;
        color: #1f0a68;
        transition: all 0.5s ease;
      }
    }
  }


.filters {
  display: flex;
  flex-wrap: wrap; // Allow wrapping to create two lines
  gap: 1.5rem;
  padding: 1rem 0;
  margin-bottom: 20px;
  border-radius: 8px;

  .name-filter,
  .price-filter,
  .location-filter,
  .rating-filter,
  .gender-filter,
  .status-filter,
  .nearby-college-filter {
    display: flex;
    flex-direction: column;

    p {
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    input,
    select {
      padding: 0.75rem;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 1rem;
      outline: none;
      transition: border-color 0.3s ease;
    }

    input:focus,
    select:focus {
      border-color: #1f0a68;
    }
  }

  .name-filter,
  .nearby-college-filter {
    width: 25%;

    input {
      width: 100%;
      padding: 0.9rem;
    }
  }

  .location-filter {
    width: 20%;

    input {
      width: 100%;
      padding: 0.9rem;
    }
  }

  .rating-filter,
  .gender-filter {
    width: 10%;
  }

  .price-filter {
    width: 20%;

    .price-values {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      font-weight: 300;

      span {
        display: flex;
        align-items: center;
      }
    }
  }

  // Updated reset-filters section to be positioned last
  .reset-filters {
    display: flex;
    height: 3.9rem;
    align-items: flex-end;
    width: 12%; // Same width as .gender-filter
    margin-left: auto; // Align to the right

    button {
      padding: 0.90rem; // Adjust padding to be consistent
      width: 100%;
      background-color: #1f0a68;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    button:hover {
      background-color: #1f0a68;
      color: white;
    }
  }
}
  .accomm-parent {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    overflow-y: scroll;

    .accomm-children {
      width: 32%;
      background-color: #ffffff;
      border-radius: 6px;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
      transition: box-shadow 0.3s ease;

      &:hover {
        box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 15px;
      }

      .accomm-gallery {
        width: 100%;
        display: flex;

        .accomm-image-container {
            width: 100%;
            height: 260px;
            overflow: hidden;
            position: relative;
            /* Added this line to establish a positioning context */
            transition: 0.5s ease-in-out !important;
        
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 8px;
              transition: transform 0.5s ease-in-out !important;
        
              &:hover {
                transform: scale(1.05);
              }
            }
        
            p {
              position: absolute;
                top: 10px;
                right: 10px;
                z-index: 1;
                color: white;
                font-size: 14px;
                padding: 5px 10px 5px 10px;
                border-radius: 5px;        
            }
          

        }
       
      }

      .accomm-child-1 {
        display: flex;
        justify-content: space-between;

        .accomm-child-1-l {
          h4 {
            font-size: 1.1rem;
          }

          p {
            font-size: 0.8rem;
          }
        }

        .accomm-child-1-r {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          svg {
            color: rgb(250, 184, 17);
          }

          p {
            font-size: 0.8rem;
          }
        }
      }

      .accomm-child-2 {
        display: flex;
        justify-content: space-between;

        .accomm-child-2-l {
          p {
            font-size: 0.8rem;

            span {
              font-size: 1.1rem;
              font-weight: 700;
            }
          }
        }

        .accomm-child-2-r {
          display: flex;
          align-items: flex-end;

          button {
            background-color: #1f0a68;
            padding: 0.3rem;
            border: 2px solid #1f0a68;
            border-radius: 5px;
            color: #ffffff;
            font-size: 0.9rem;
            cursor: pointer;
            transition: all 0.3s ease;
          }

          button:hover {
            background-color: #ffffff;
            border: 2px solid #1f0a68;
            color: #1f0a68;
          }
        }
      }
    }
  }

  // Responsive adjustments
  @media (max-width: 768px) {
    .accomm-children {
      width: 48%; // Adjusts to 2 items per row on tablet
    }
  }

  @media (max-width: 576px) {
    .accomm-children {
      width: 100%; // Adjusts to 1 item per row on mobile
    }

    .filters {
      flex-direction: column;
      gap: 1rem;
    }
  }
}