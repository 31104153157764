.Notifications-container {
  position: absolute;
  top: 100px;
  right: 17px;
  height: 80vh;
  z-index: 9;
  width: 500px;
  background-color: #fff;
  border-radius: 20px;
  padding: 24px;
  overflow-y: auto;
  box-shadow: 2px 2px 20px rgba(50, 42, 117, 0.11);
  .notifications-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    .notifications-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
